<template>
  <form class="main-body" v-if="editableOrganisation && isLoaded">
    <h2 class="subheading">{{ heading }}</h2>

    <!-- <section class="quick-link-wrapper" > -->

    <p
      class="quick-link-wrapper"
      v-if="quickLink"
      @click="navigateToRoute(quickLink)"
    >
      Edit this organisation's detail page
    </p>

    <!-- <section>
        <v-stepper>
          <v-stepper-header>
            <v-stepper-step step="1">
              Ad unit details
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="2">
              Ad sizes
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3">
              Ad templates
            </v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </section> -->

    <!-- </section> -->

    <section class="section-container">
      <h3 class="subheading mt-0">About</h3>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="orgName">Organisation Name</label>
          <v-text-field
            id="orgName"
            placeholder="Organisation Name"
            solo
            flat
            v-model="editableOrganisation.name"
            :rules="[rules.required]"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i><b>Required.</b> </i>
          </p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="subtitle">Subtitle</label>
          <v-text-field
            id="subtitle"
            placeholder="Subtitle"
            solo
            flat
            v-model="editableOrganisation.subtitle"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="organisationTypeID"
            >Organisation Type</label
          >
          <v-select
            class="filters"
            v-if="organisationTypeFilters.length > 0"
            v-model="editableOrganisation.organisationTypeID"
            :items="organisationTypeFilters"
            placeholder="Select Organisation Type"
            flat
            :rules="[rules.required]"
          >
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              ><b>Required.</b> Only existing organisation types are
              available.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="published">Published</label>
          <v-checkbox
            id="published"
            solo
            flat
            v-model="editableOrganisation.published"
          ></v-checkbox>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Only published organisations will be displayed.</i></p>
        </v-flex>
      </v-layout>
    </section>

    <section class="section-container">
      <h3 class="subheading mt-0">Images</h3>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <ImageUploader
            mb-3
            :title="'Featured Image - Small'"
            :widthOptionEnabled="false"
            :mediaLibraryID="
              editableOrganisation.featuredImageSmallMediaLibraryID
            "
            @update="updateFeaturedImageSmall($event)"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >Optional. This will be replace the logo on the listing card.<br />Aspect
              ratio for the small featured image should be 16:9</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <ImageUploader
            mb-3
            :title="'Featured Image - Large'"
            :widthOptionEnabled="false"
            :mediaLibraryID="
              editableOrganisation.featuredImageLargeMediaLibraryID
            "
            @update="updateFeaturedImageLarge($event)"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >Optional. This will be displayed at the top of the widget detail.
              TODO - recommended size</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <ImageUploader
            mb-3
            :title="'Organisation Logo'"
            :widthOptionEnabled="false"
            :mediaLibraryID="editableOrganisation.logoMediaLibraryID"
            @update="updateLogo($event)"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >Optional. This will be displayed on the listing card.
              <br />Aspect ratio for the logo should be - 1:1</i
            >
          </p>
        </v-flex>
      </v-layout>
    </section>

    <section class="section-container">
      <h3 class="subheading mt-0">Content</h3>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="overviewContent">Overview Content</label>
          <tinymce-editor
            v-model="editableOrganisation.overviewContent"
            :rules="[rules.required]"
            api-key="rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca"
            :init="{
              height: '500px',
              menubar: false,
              plugins: 'table link paste wordcount hr lists textpattern',
              toolbar:
                'undo redo | styleselect | bold italic | \
              link blockquote table | \
              hr | \
              bullist numlist | removeformat',
              resize: false,
              textpattern_patterns: [
                {
                  start: '//button',
                  replacement: '<a class=\'button \'>Button here</a>',
                },
              ],
              link_class_list: [
                { title: 'None', value: '' },
                {
                  title: 'Primary on Dark',
                  value: 'button button--primary-dark',
                },
                {
                  title: 'Primary on Light',
                  value: 'button button--primary-light',
                },
                { title: 'Ghost on Dark', value: 'button button--ghost-dark' },
                {
                  title: 'Ghost on Light',
                  value: 'button button--ghost-light',
                },
                { title: 'Label on Dark', value: 'button button--label-dark' },
                {
                  title: 'Label on Light',
                  value: 'button button--label-light',
                },
              ],
              style_formats: [
                {
                  title: 'Headers',
                  items: [
                    //{title: 'Header 1', format: 'h1'},
                    { title: 'Header 2', format: 'h2' },
                    { title: 'Header 3', format: 'h3' },
                    { title: 'Header 4', format: 'h4' },
                    { title: 'Header 5', format: 'h5' },
                    { title: 'Header 6', format: 'h6' },
                  ],
                },
                {
                  title: 'Inline',
                  items: [
                    { title: 'Bold', icon: 'bold', format: 'bold' },
                    { title: 'Italic', icon: 'italic', format: 'italic' },
                    {
                      title: 'Underline',
                      icon: 'underline',
                      format: 'underline',
                    },
                    //{title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough'},
                    //{title: 'Superscript', icon: 'superscript', format: 'superscript'},
                    //{title: 'Subscript', icon: 'subscript', format: 'subscript'},
                    //{title: 'Code', icon: 'code', format: 'code'}
                  ],
                },
                {
                  title: 'Blocks',
                  items: [
                    { title: 'Paragraph', format: 'p' },
                    { title: 'Blockquote', format: 'blockquote' },
                    //{title: 'Div', format: 'div'},
                    //{title: 'Pre', format: 'pre'}
                  ],
                },
                {
                  title: 'Alignment',
                  items: [
                    { title: 'Left', icon: 'alignleft', format: 'alignleft' },
                    {
                      title: 'Center',
                      icon: 'aligncenter',
                      format: 'aligncenter',
                    },
                    {
                      title: 'Right',
                      icon: 'alignright',
                      format: 'alignright',
                    },
                    //{title: 'Justify', icon: 'alignjustify', format: 'alignjustify'}
                  ],
                },
                { title: 'Small', selector: 'p', classes: 'small' },
              ],
              style_formats_merge: false,
              block_formats:
                'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote',
            }"
          ></tinymce-editor>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              ><b>Required.</b> This is the main content for the organisation,
              which will appear in their listing area.</i
            >
          </p>
        </v-flex>
      </v-layout>
    </section>

    <section class="section-container">
      <h3 class="subheading mt-0">Page Links</h3>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="internalPageURL">Detail Page</label>
          <v-text-field
            id="internalPageURL"
            placeholder="/internal-page-slug"
            solo
            flat
            v-model="editableOrganisation.internalPageURL"
            @blur="nullPageID()"
          ></v-text-field>
          <page-selector
            @pageSelected="setDetailPage($event)"
            @pageUrl="setPageUrl($event)"
          >
            <v-btn
              class="image-editor__image-upload"
              color="accent"
              type="button"
              depressed
            >
              <v-icon small>add</v-icon>
              Choose a page
            </v-btn>
          </page-selector>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This is the internal website page that you want to display the
              detail widget on. The link to this page will be displayed on the
              organisation detail card.<br />Where possible you should use the
              page selector button, as this will create a link to the detail
              page at the top of this form.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="internalPageButtonText"
            >Detail Page - Link Text</label
          >
          <v-text-field
            id="internalPageButtonText"
            placeholder="Read More"
            solo
            flat
            v-model="editableOrganisation.internalPageButtonText"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This is the text that will appear on the link to the detail
              page.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="internalPageID">Detail Page ID</label>
          <v-text-field
            id="internalPageID"
            solo
            flat
            disabled
            v-model="editableOrganisation.internalPageID"
            :rules="[rules.required]"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>[:TODO] Grab the ID from the page slug</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="externalPageURL">External PageURL</label>
          <v-text-field
            id="externalPageURL"
            type="text"
            placeholder="External PageURL"
            solo
            flat
            v-model="editableOrganisation.externalPageURL"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >If your organisation has it's own page or site add the url
              here.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="externalPageButtonText"
            >External Page Button Text</label
          >
          <v-text-field
            id="externalPageButtonText"
            placeholder="Short Name"
            solo
            flat
            v-model="editableOrganisation.externalPageButtonText"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i>This is the text that will appear on the link to the site.</i>
          </p>
        </v-flex>
      </v-layout>
    </section>

    <section class="section-container">
      <h3 class="subheading mt-0">Key Contact Details</h3>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="keyContactName">Key Contact Name</label>
          <v-text-field
            id="keyContactName"
            type="text"
            placeholder="Key Contact Name"
            solo
            flat
            v-model="editableOrganisation.keyContactName"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >To be used if the organisation has a named contact that should
              appear on it's information.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="keyContactEmail">Key Contact Email</label>
          <v-text-field
            id="keyContactEmail"
            placeholder="Key Contact Email"
            solo
            flat
            v-model="editableOrganisation.keyContactEmail"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >To be used if the organisation has a named contact that should
              appear on it's information.<br />
              The email will only appear if a Key Contact Name is provided.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="keyContactPhone">Key Contact Phone</label>
          <v-text-field
            id="keyContactPhone"
            type="text"
            placeholder="Key Contact Phone"
            solo
            flat
            v-model="editableOrganisation.keyContactPhone"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >To be used if the organisation has a named contact that should
              appear on it's information.<br />
              The phone number will only appear if a Key Contact Name is
              provided.</i
            >
          </p>
        </v-flex>
      </v-layout>
    </section>

    <section class="section-container">
      <h3 class="subheading mt-0">General Information</h3>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="contact">General Information</label>
          <v-textarea
            id="contact"
            type="text"
            placeholder="General Contact Information"
            solo
            flat
            v-model="editableOrganisation.contact"
          >
          </v-textarea>
          <tinymce-editor
            v-model="editableOrganisation.contact"
            :rules="[rules.required]"
            api-key="rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca"
            :init="{
              height: '500px',
              menubar: false,
              plugins: 'table link paste wordcount hr lists textpattern',
              toolbar:
                'undo redo | styleselect | bold italic | \
              link table | \
              hr | \
              bullist numlist | removeformat',
              resize: false,
              textpattern_patterns: [
                {
                  start: '//button',
                  replacement: '<a class=\'button \'>Button here</a>',
                },
              ],
              link_class_list: [
                { title: 'None', value: '' },
                {
                  title: 'Primary on Dark',
                  value: 'button button--primary-dark',
                },
                {
                  title: 'Primary on Light',
                  value: 'button button--primary-light',
                },
                { title: 'Ghost on Dark', value: 'button button--ghost-dark' },
                {
                  title: 'Ghost on Light',
                  value: 'button button--ghost-light',
                },
                { title: 'Label on Dark', value: 'button button--label-dark' },
                {
                  title: 'Label on Light',
                  value: 'button button--label-light',
                },
              ],
              style_formats: [
                {
                  title: 'Headers',
                  items: [
                    { title: 'Header 5', format: 'h5' },
                    { title: 'Header 6', format: 'h6' },
                  ],
                },
                {
                  title: 'Inline',
                  items: [
                    { title: 'Bold', icon: 'bold', format: 'bold' },
                    { title: 'Italic', icon: 'italic', format: 'italic' },
                    {
                      title: 'Underline',
                      icon: 'underline',
                      format: 'underline',
                    },
                    //{title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough'},
                    //{title: 'Superscript', icon: 'superscript', format: 'superscript'},
                    //{title: 'Subscript', icon: 'subscript', format: 'subscript'},
                    //{title: 'Code', icon: 'code', format: 'code'}
                  ],
                },
                {
                  title: 'Blocks',
                  items: [
                    { title: 'Paragraph', format: 'p' },
                    //{title: 'Blockquote', format: 'blockquote'},
                    //{title: 'Div', format: 'div'},
                    //{title: 'Pre', format: 'pre'}
                  ],
                },
                {
                  title: 'Alignment',
                  items: [
                    { title: 'Left', icon: 'alignleft', format: 'alignleft' },
                    {
                      title: 'Center',
                      icon: 'aligncenter',
                      format: 'aligncenter',
                    },
                    {
                      title: 'Right',
                      icon: 'alignright',
                      format: 'alignright',
                    },
                    //{title: 'Justify', icon: 'alignjustify', format: 'alignjustify'}
                  ],
                },
                //{title: 'Small', selector: 'p', classes: 'small'}
              ],
              style_formats_merge: false,
              block_formats:
                'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote',
            }"
          ></tinymce-editor>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This is for any useful information for the organisation.</i></p>
        </v-flex>
      </v-layout>
    </section>

    <section class="section-container">
      <h3 class="subheading mt-0">Contact</h3>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="email">Contact Email</label>
          <v-text-field
            id="email"
            type="text"
            placeholder="Email"
            solo
            flat
            v-model="editableOrganisation.email"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>The main email for the organisation.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="phone">Contact Phone</label>
          <v-text-field
            id="phone"
            type="text"
            placeholder="Phone"
            solo
            flat
            v-model="editableOrganisation.phone"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>The main phone number for the organisation.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="facebook">Facebook</label>
          <v-text-field
            id="facebook"
            type="text"
            placeholder="Facebook"
            solo
            flat
            v-model="editableOrganisation.facebook"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >Only enter the account detail.<br /><span class="faded"
                >https://www.facebook.com/</span
              >Your-Account</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="twitter">X</label>
          <v-text-field
            id="twitter"
            type="text"
            placeholder="Twitter"
            solo
            flat
            v-model="editableOrganisation.twitter"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >Only enter the account detail.<br /><span class="faded"
                >https://x.com/</span
              >@Your-Account</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="instagram">Instagram</label>
          <v-text-field
            id="instagram"
            type="text"
            placeholder="Instagram"
            solo
            flat
            v-model="editableOrganisation.instagram"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >Only enter the account details.<br /><span class="faded"
                >https://www.instagram.com/</span
              >Your-Account</i
            >
          </p>
        </v-flex>
      </v-layout>
    </section>

    <section class="section-container">
      <h3 class="subheading mt-0">Location</h3>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="country">Country</label>
          <v-autocomplete
            v-model="editableOrganisation.country"
            autocomplete="nope"
            :items="countriesList"
            item-value="alpha2Code"
            item-text="enShortName"
            no-data-text="loading..."
            flat
            clearable
            dense
          >
          </v-autocomplete>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="region">Region</label>
          <v-text-field
            id="region"
            placeholder="Region"
            solo
            flat
            v-model="editableOrganisation.region"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >If you require additional filtering such as Counties or States
              you can use a custom region. Regions are case sensitive and can
              only be filtered individually.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="address">Address</label>
          <v-textarea
            id="address"
            type="text"
            placeholder="Address"
            autocomplete="nope"
            solo
            flat
            v-model="editableOrganisation.address"
          >
          </v-textarea>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>HTML tags are allowed for EG line breaks.</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <map-picker
            :latVal="editableOrganisation.latLng.lat"
            :lngVal="editableOrganisation.latLng.lng"
            :displayName="editableOrganisation.name"
            @latUpdate="updateLat($event)"
            @lngUpdate="updateLng($event)"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              ><b>A map location is required.</b> Click the map to place the
              organisation.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="lat">Lat</label>
          <v-text-field
            id="lat"
            placeholder="Lat"
            type="number"
            solo
            flat
            v-model="editableOrganisation.latLng.lat"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="lng">Lng</label>
          <v-text-field
            id="lng"
            placeholder="Lng"
            type="number"
            solo
            flat
            v-model="editableOrganisation.latLng.lng"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
    </section>

    <v-btn type="button" depressed @click="updateOrg()" color="accent">
      <v-icon small>save</v-icon>
      &nbsp;Save
    </v-btn>
  </form>
</template>

<script>
import axios from "axios";
import ImageUploader from "@/components/Library/ImageUploader";
import MapPicker from "@/components/Library/MapPicker";
import Editor from "@tinymce/tinymce-vue";
import PageSelector from "@/components/Library/PageSelector";

export default {
  name: "EditOrganisation",

  components: {
    ImageUploader,
    MapPicker,
    "tinymce-editor": Editor,
    PageSelector,
  },

  created() {
    if (this.$route.path != "/organisations/create") {
      this.getOrgData();
      this.heading = "Editing Organisation";
      this.isExistingOrg = true;
    } else {
      this.heading = "Creating New Organisation";
      this.isExistingOrg = false;
      this.showFields;
      this.isLoaded = true;
    }

    this.getCountries();
    this.getOrganisationTypeFilters();
  },

  mounted() {
    if (this.editableOrganisation.internalPageID) {
      this.quickLink = this.editableOrganisation.internalPageID;
    }
  },

  data: () => ({
    heading: null,
    orgId: null,
    isLoaded: false,
    isExistingOrg: null,
    quickLink: null,
    editableOrganisation: {
      organisationTypeID: null,
      name: null,
      nameSearch: null,
      country: null,
      region: null,
      internalPageURL: null,
      internalPageID: null,
      internalPageButtonText: null,
      externalPageURL: null,
      externalPageButtonText: null,
      keyContactName: null,
      keyContactEmail: null,
      keyContactPhone: null,
      address: null,
      contact: null,
      overviewContent: null,
      subtitle: null,
      content: null,
      published: null,
      latLng: {
        lat: null,
        lng: null,
      },
      email: null,
      phone: null,
      twitter: null,
      instagram: null,
      facebook: null,
      logoMediaLibraryID: null,
      logoImageKey: null,
      featuredImageSmallMediaLibraryID: null,
      featuredImageSmallImageKey: null,
      featuredImageLargeMediaLibraryID: null,
      featuredImageLargeImageKey: null,
      position: null,
    },
    countriesList: [],
    countriesLoaded: false,
    organisationTypeFilters: [],
    rules: {
      required: (value) => !!value || "Required.",
    },
  }),

  methods: {
    getOrgData() {
      this.$store.commit("startLoading");
      this.orgId = this.$route.params.id.substring(1);
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_ORGANISATIONAPI}/organisations/${this.orgId}`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            console.log("got org data?", response);
            this.editableOrganisation = response.data.body.Item;
            this.$store.commit("completeLoading");
            this.isLoaded = true;
            if (response.data.body.Item.internalPageID != null) {
              this.quickLink = response.data.body.Item.internalPageID;
            }
          })
          .catch((e) => {
            console.error("Problem receiving org type", e);
            this.$store.commit("completeLoading");
          });
      });
    },

    updateOrg() {
      this.$store.commit("startLoading");

      if (this.isExistingOrg) {
        console.log("method for existing org");
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "PUT",
            url: `${process.env.VUE_APP_ORGANISATIONAPI}/organisations/${this.editableOrganisation.organisationID}`,
            headers: { Authorization: jwt },
            data: {
              organisationTypeID: this.editableOrganisation.organisationTypeID
                ? this.editableOrganisation.organisationTypeID
                : null,
              name: this.editableOrganisation.name
                ? this.editableOrganisation.name
                : null,
              nameSearch: this.editableOrganisation.name
                ? this.editableOrganisation.name.toLowerCase()
                : null,
              country: this.editableOrganisation.country
                ? this.editableOrganisation.country
                : null,
              region: this.editableOrganisation.region
                ? this.editableOrganisation.region
                : null,
              internalPageURL: this.editableOrganisation.internalPageURL
                ? this.editableOrganisation.internalPageURL
                : null,
              internalPageID: this.editableOrganisation.internalPageID
                ? this.editableOrganisation.internalPageID
                : null,
              internalPageButtonText: this.editableOrganisation
                .internalPageButtonText
                ? this.editableOrganisation.internalPageButtonText
                : null,
              externalPageURL: this.editableOrganisation.externalPageURL
                ? this.editableOrganisation.externalPageURL
                : null,
              externalPageButtonText: this.editableOrganisation
                .externalPageButtonText
                ? this.editableOrganisation.externalPageButtonText
                : null,
              keyContactName: this.editableOrganisation.keyContactName
                ? this.editableOrganisation.keyContactName
                : null,
              keyContactEmail: this.editableOrganisation.keyContactEmail
                ? this.editableOrganisation.keyContactEmail
                : null,
              keyContactPhone: this.editableOrganisation.keyContactPhone
                ? this.editableOrganisation.keyContactPhone
                : null,
              address: this.editableOrganisation.address
                ? this.editableOrganisation.address
                : null,
              contact: this.editableOrganisation.contact
                ? this.editableOrganisation.contact
                : null,
              overviewContent: this.editableOrganisation.overviewContent
                ? this.editableOrganisation.overviewContent
                : null,
              subtitle: this.editableOrganisation.subtitle
                ? this.editableOrganisation.subtitle
                : null,
              content: this.editableOrganisation.content
                ? this.editableOrganisation.content
                : null,
              published: this.editableOrganisation.published ? 1 : 0,
              latLng: {
                lat: this.editableOrganisation.latLng.lat
                  ? this.editableOrganisation.latLng.lat
                  : null,
                lng: this.editableOrganisation.latLng.lng
                  ? this.editableOrganisation.latLng.lng
                  : null,
              },
              email: this.editableOrganisation.email
                ? this.editableOrganisation.email
                : null,
              phone: this.editableOrganisation.phone
                ? this.editableOrganisation.phone
                : null,
              twitter: this.editableOrganisation.twitter
                ? this.editableOrganisation.twitter
                : null,
              instagram: this.editableOrganisation.instagram
                ? this.editableOrganisation.instagram
                : null,
              facebook: this.editableOrganisation.facebook
                ? this.editableOrganisation.facebook
                : null,
              logoMediaLibraryID: this.editableOrganisation.logoMediaLibraryID
                ? this.editableOrganisation.logoMediaLibraryID
                : null,
              logoImageKey: this.editableOrganisation.logoImageKey
                ? this.editableOrganisation.logoImageKey
                : null,
              featuredImageLargeMediaLibraryID: this.editableOrganisation
                .featuredImageLargeMediaLibraryID
                ? this.editableOrganisation.featuredImageLargeMediaLibraryID
                : null,
              featuredImageLargeImageKey: this.editableOrganisation
                .featuredImageLargeImageKey
                ? this.editableOrganisation.featuredImageLargeImageKey
                : null,
              featuredImageSmallMediaLibraryID: this.editableOrganisation
                .featuredImageSmallMediaLibraryID
                ? this.editableOrganisation.featuredImageSmallMediaLibraryID
                : null,
              featuredImageSmallImageKey: this.editableOrganisation
                .featuredImageSmallImageKey
                ? this.editableOrganisation.featuredImageSmallImageKey
                : null,
              position: this.editableOrganisation.position
                ? this.editableOrganisation.position
                : null,
            },
          })
            .then((response) => {
              console.log("PUT existing response", response);
              this.$store.commit("completeLoading");
              window.alert("Successfully Saved");
            })
            .catch((e) => {
              console.error("Problem PUTing existing org type", e);
              this.$store.commit("completeLoading");
              window.alert("Couldn't save - Check your required fields");
            });
        });
      } else {
        console.log("method for new org type");
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "POST",
            url: `${process.env.VUE_APP_ORGANISATIONAPI}/organisations`,
            headers: { Authorization: jwt },
            data: {
              organisationTypeID: this.editableOrganisation.organisationTypeID
                ? this.editableOrganisation.organisationTypeID
                : null,
              name: this.editableOrganisation.name
                ? this.editableOrganisation.name
                : null,
              nameSearch: this.editableOrganisation.name
                ? this.editableOrganisation.name.toLowerCase()
                : null,
              country: this.editableOrganisation.country
                ? this.editableOrganisation.country
                : null,
              region: this.editableOrganisation.region
                ? this.editableOrganisation.region
                : null,
              internalPageURL: this.editableOrganisation.internalPageURL
                ? this.editableOrganisation.internalPageURL
                : null,
              internalPageID: this.editableOrganisation.internalPageID
                ? this.editableOrganisation.internalPageID
                : null,
              internalPageButtonText: this.editableOrganisation
                .internalPageButtonText
                ? this.editableOrganisation.internalPageButtonText
                : null,
              externalPageURL: this.editableOrganisation.externalPageURL
                ? this.editableOrganisation.externalPageURL
                : null,
              externalPageButtonText: this.editableOrganisation
                .externalPageButtonText
                ? this.editableOrganisation.externalPageButtonText
                : null,
              keyContactName: this.editableOrganisation.keyContactName
                ? this.editableOrganisation.keyContactName
                : null,
              keyContactEmail: this.editableOrganisation.keyContactEmail
                ? this.editableOrganisation.keyContactEmail
                : null,
              keyContactPhone: this.editableOrganisation.keyContactPhone
                ? this.editableOrganisation.keyContactPhone
                : null,
              address: this.editableOrganisation.address
                ? this.editableOrganisation.address
                : null,
              contact: this.editableOrganisation.contact
                ? this.editableOrganisation.contact
                : null,
              overviewContent: this.editableOrganisation.overviewContent
                ? this.editableOrganisation.overviewContent
                : null,
              subtitle: this.editableOrganisation.subtitle
                ? this.editableOrganisation.subtitle
                : null,
              content: this.editableOrganisation.content
                ? this.editableOrganisation.content
                : null,
              published: this.editableOrganisation.published ? 1 : 0,
              latLng: {
                lat: this.editableOrganisation.latLng.lat
                  ? parseFloat(this.editableOrganisation.latLng.lat)
                  : null,
                lng: this.editableOrganisation.latLng.lng
                  ? parseFloat(this.editableOrganisation.latLng.lng)
                  : null,
              },
              email: this.editableOrganisation.email
                ? this.editableOrganisation.email
                : null,
              phone: this.editableOrganisation.phone
                ? this.editableOrganisation.phone
                : null,
              twitter: this.editableOrganisation.twitter
                ? this.editableOrganisation.twitter
                : null,
              instagram: this.editableOrganisation.instagram
                ? this.editableOrganisation.instagram
                : null,
              facebook: this.editableOrganisation.facebook
                ? this.editableOrganisation.facebook
                : null,
              logoMediaLibraryID: this.editableOrganisation.logoMediaLibraryID
                ? this.editableOrganisation.logoMediaLibraryID
                : null,
              logoImageKey: this.editableOrganisation.logoImageKey
                ? this.editableOrganisation.logoImageKey
                : null,
              featuredImageLargeMediaLibraryID: this.editableOrganisation
                .featuredImageLargeMediaLibraryID
                ? this.editableOrganisation.featuredImageLargeMediaLibraryID
                : null,
              featuredImageLargeImageKey: this.editableOrganisation
                .featuredImageLargeImageKey
                ? this.editableOrganisation.featuredImageLargeImageKey
                : null,
              featuredImageSmallMediaLibraryID: this.editableOrganisation
                .featuredImageSmallMediaLibraryID
                ? this.editableOrganisation.featuredImageSmallMediaLibraryID
                : null,
              featuredImageSmallImageKey: this.editableOrganisation
                .featuredImageSmallImageKey
                ? this.editableOrganisation.featuredImageSmallImageKey
                : null,
              position: this.editableOrganisation.position
                ? this.editableOrganisation.position
                : null,
            },
          })
            .then((response) => {
              console.log("POST new response", response);
              this.$store.commit("completeLoading");
              //Grabs the new ID and changes heading / route
              this.$router.push({
                path: `/organisations/${response.data.body.organisationID}`,
              });
              this.heading = "Editing Organisation";
              this.isExistingOrg = true;
              this.isLoaded = true;
              window.alert("Successfully Saved");
            })
            .catch((e) => {
              console.error("Problem posting new org type", e);
              this.$store.commit("completeLoading");
              window.alert("Couldn't save - Check your required fields");
            });
        });
      }
    },
    updateLat(lattitude) {
      this.editableOrganisation.latLng.lat = lattitude;
    },
    updateLng(longitude) {
      this.editableOrganisation.latLng.lng = longitude;
    },
    updateLogo(image) {
      this.editableOrganisation.logoMediaLibraryID = image.mediaLibraryID;
      this.editableOrganisation.logoImageKey = image.imageKey;
    },
    updateFeaturedImageSmall(image) {
      this.editableOrganisation.featuredImageSmallMediaLibraryID =
        image.mediaLibraryID;
      this.editableOrganisation.featuredImageSmallImageKey = image.imageKey;
    },
    updateFeaturedImageLarge(image) {
      this.editableOrganisation.featuredImageLargeMediaLibraryID =
        image.mediaLibraryID;
      this.editableOrganisation.featuredImageLargeImageKey = image.imageKey;
    },
    getCountries() {
      this.countriesLoaded = false;
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_COUNTRIESAPI}`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            this.countriesList = response.data;
            this.countriesLoaded = true;
          })
          .catch((e) => {
            console.error("Problem receiving countries", e);
          });
      });
    },
    getOrganisationTypeFilters() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_ORGANISATIONAPI}/organisationtypes`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            response.data.body.Items.forEach((item) => {
              this.organisationTypeFilters.push(item.organisationTypeID);
            });
          })
          .catch((e) => {
            console.error("Problem receiving orgtypes", e);
            this.$store.commit("completeLoading");
          });
      });
    },
    setDetailPage(page) {
      this.quickLink = page;
      this.editableOrganisation.internalPageID = page;
    },
    navigateToRoute(dest) {
      this.$router.push(`/pages/${dest}`);
    },
    setPageUrl(page) {
      this.editableOrganisation.internalPageURL = "/" + page;
    },
    nullPageID() {
      this.quickLink = null;
      this.editableOrganisation.internalPageID = null;
      console.log(this.editableOrganisation);
    },
  },
};
</script>

<style scoped lang='scss'>
@import "../../styles/settings";

.half {
  width: 50%;
}

.section-divider {
  margin-bottom: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dfe3e9;
}

.player {
  &__checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__toggle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__panel {
    border: 1px solid $lightgrey;
    background-color: $white;
    padding: 20px;
    margin-bottom: 20px;
    &-details {
      flex-direction: column;
      @media (min-width: 960px) {
        flex-direction: row;
      }
    }
    &-img {
      img {
        width: 100%;
      }
    }
    &-opta {
      &-cb {
        label {
          margin-bottom: 0;
        }
      }
    }
    &--column {
      flex-direction: column;
      width: 100%;
    }
  }
}
.input {
  &__textfield {
    &--basic {
      margin-top: 0;
    }
  }
}

.tiny-mce-block {
  margin-bottom: 16px;
}

.quick-link-wrapper {
  padding: 16px;
  font-style: italic;
  background-color: color(warning);
  color: #000;
  border-radius: 8px;
  border: 1px solid color(newHighlightBorder);

  &:hover {
    cursor: pointer;
  }
}

.faded {
  opacity: 0.5;
}
</style>
