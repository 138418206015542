<template>

  <form class="main-body" v-if="editableOrganisationType && isLoaded">

    <h2 class="subheading">{{heading}}</h2>

    <section class="section-container">
      <h3 class="subheading mt-0">Details</h3>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="organisationType">Organisation Type</label>
          <v-text-field id="organisationType" placeholder="Organisation Type" solo flat v-model="editableOrganisationType.name" :rules="[rules.required]"></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i><b>Required. </b>An organisation type is used to display related organisations as a group.<br /> EG: Schools, Arenas, Public Halls</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="organisationTypeID">Organisation Type ID</label>
          <v-text-field id="organisationTypeID" type="text" placeholder="Organisation Type ID" solo flat v-model="editableOrganisationType.organisationTypeID" :rules="[rules.required]">
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i><b>Required. </b>This needs to be a unique ID, and is used to manage the organisation data<br />EG: lower-case-name-with-hyphens</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="organisationTypeShortName">Short Name</label>
          <v-text-field id="organisationTypeShortName" placeholder="Short Name" solo flat  v-model="editableOrganisationType.shortName" :rules="[rules.required]"></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i><b>Required. </b>The short name may be displayed on smaller screens, devices and buttons.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="organisationTypeStatus">Published</label>
          <v-checkbox id="organisationTypeStatus" solo flat v-model="editableOrganisationType.published"></v-checkbox>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Only published organisation types will be displayed.</i></p>
        </v-flex>
      </v-layout>
    </section>

    <section class="section-container">
      <h3 class="subheading mt-0">Design</h3>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="textColor">Text Colour</label>
          <v-text-field id="textColor" type="text" placeholder="Text Colour" solo flat v-model="editableOrganisationType.textColour" :rules="[rules.required]">
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i><b>Required. </b>Please add a hex code for the text colour.<br />EG: #000000<br />This is used to style map icons &  set page styles in the directory listing.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="backgroundColor">Background Colour</label>
          <v-text-field id="backgroundColor" type="text" placeholder="Background Colour" solo flat v-model="editableOrganisationType.backgroundColour" :rules="[rules.required]">
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i><b>Required. </b>Please add a hex code for the background colour.<br />EG: #FFFFFF<br />This is used to style map icons & set page styles in the directory listing.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="backgroundColor">Icon Choice</label>
          <label class="label" for="ctaIcon">Icon</label>
          <v-select
            v-model="editableOrganisationType.buttonIcon"
            :items="icons"
          >
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Choose an icon if you would like it to appear on the 'organisation type' filter button.</i></p>
        </v-flex>
      </v-layout>

      <!-- <v-layout row class="section-divider"> -->
      <!-- <v-layout row>
        <v-flex mr-5 class="half">
          <ImageUploader mb-3 :title="'Custom Map Pin'" :widthOptionEnabled="false"  :mediaLibraryID="editableOrganisationType.mapPinMediaLibraryID" @update="updateMapPin($event)" />
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will override the default map pin style for this organisation type.</i></p>
        </v-flex>
      </v-layout> -->
    </section>

    <section class="section-container">
      <h3 class="subheading mt-0">Page Details</h3>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <ImageUploader mb-3 :title="'Organisation Type - Logo'" :widthOptionEnabled="false"  :mediaLibraryID="editableOrganisationType.logoMediaLibraryID" @update="updateLogo($event)" />
        </v-flex>
        <v-flex mt-4 class="half">
          <p><b>Not in use currently</b></p>
          <p><i></i></p>
        </v-flex>
      </v-layout>

      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="infoPage">Information Page</label>
            <v-text-field id="infoPage" type="text" placeholder="Information Page" solo flat disabled v-model="editableOrganisationType.informationPage">
            </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><b>Not in use currently</b></p>
          <p><i>If an information page is provided a link to this page will appear on the organisation listing card in map and list view.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="infoButtonText">Information Page Button Text</label>
          <v-text-field id="infoButtonText" placeholder="Short Name" solo flat disabled v-model="editableOrganisationType.informationPageButtonText"></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><b>Not in use currently</b></p>
          <p><i>This will be the text in the link to the information page.</i></p>
        </v-flex>
      </v-layout>
    </section>

    <v-btn
      type="button"
      depressed
      @click="updateOrgType()"
      color="accent"
    >
      <v-icon small>save</v-icon>
      &nbsp;Save
    </v-btn>

  </form>
</template>

<script>
import axios from "axios"
import ImageUploader from "@/components/Library/ImageUploader"

export default {
  name: "EditOrganisationType",

  components: {
    ImageUploader
  },

  props: {
    organisationType: {
      type: Object,
      required: false
    }
  },

  created() {
    if (this.$route.path != "/organisationTypes/create") {
      this.getOrgTypeData()
      this.heading = "Editing Organisation type"
      this.isExistingType = true

    } else {
      this.heading = "Creating New Organisation type"
      this.isExistingType = false
      this.showFields
      this.isLoaded = true
    }
  },

  data: () => ({
    // For Some reason API isn't loading here
    api: process.env.VUE_APP_ORGANISATIONAPI,
    isExistingType: null,
    isLoaded: false,
    heading: "",
    orgTypeId: null,
    editableOrganisationType: {
      name: null,
      organisationTypeID: null,
      shortName: null,
      published: null,
      informationPage: null,
      informationPageButtonText: null,
      textColour: null,
      backgroundColour: null,
      buttonIcon: null,
      logoMediaLibraryID: null,
      logoImageKey: null,
      mapPinMediaLibraryID: null,
      mapPinImageKey: null,
      renderingPath: null
    },
    rules: {
      required: value => !! value || "Required."
    },
    icons: ["",
      "Article",
      "Attendance",
      "Ball",
      "Basket",
      "Burger",
      "Camera",
      "ChevronLeft",
      "ChevronRight",
      "Close",
      "Download",
      "Email",
      "Facebook",
      "Feed",
      "Hospitality",
      "Info",
      "Instagram",
      "Lineups",
      "Match",
      "Padlock",
      "Phone",
      "Player",
      "Plus",
      "Quiz",
      "Scores",
      "Search",
      "Shirt",
      "SignIn",
      "Snapchat",
      "Stats",
      "Tick",
      "Tickets",
      "Twitter",
      "Video",
      "Whistle",
      "Youtube"
    ]
  }),

  methods: {
    getOrgTypeData() {
      this.$store.commit("startLoading")
      this.orgTypeId = this.$route.params.id.substring(1)

      this.$getCurrentUserJwToken().subscribe((jwt) => {

        axios({
          method: "GET",
          url: `${process.env.VUE_APP_ORGANISATIONAPI}/organisationtypes/${this.orgTypeId}`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("got org type data?", response)
          this.editableOrganisationType = response.data.body.Item
          this.$store.commit("completeLoading")
          this.isLoaded = true
        })
        .catch(e => {
          console.error("Problem receiving org type", e)
          this.$store.commit("completeLoading")
        })
      })
    },

    updateOrgType() {
      this.$store.commit("startLoading")

      if (this.isExistingType) {
        console.log("method for existing org type")
        this.$getCurrentUserJwToken().subscribe((jwt) => {

          axios({
            method: "PUT",
            url: `${process.env.VUE_APP_ORGANISATIONAPI}/organisationtypes/${this.editableOrganisationType.organisationTypeID}`,
            headers: { "Authorization": jwt },
            data: {
              name: this.editableOrganisationType.name ? this.editableOrganisationType.name : null,
              organisationTypeID: this.editableOrganisationType.organisationTypeID ? this.editableOrganisationType.organisationTypeID : null,
              shortName: this.editableOrganisationType.shortName ? this.editableOrganisationType.shortName : null,
              published: this.editableOrganisationType.published ? 1 : 0,
              informationPage: this.editableOrganisationType.informationPage ? this.editableOrganisationType.informationPage : null,
              informationPageButtonText: this.editableOrganisationType.informationPageButtonText ? this.editableOrganisationType.informationPageButtonText : null,
              textColour: this.editableOrganisationType.textColour ? this.editableOrganisationType.textColour : null,
              backgroundColour: this.editableOrganisationType.backgroundColour ? this.editableOrganisationType.backgroundColour : null,
              buttonIcon: this.editableOrganisationType.buttonIcon ? this.editableOrganisationType.buttonIcon : null,
              logoMediaLibraryID: this.editableOrganisationType.logoMediaLibraryID ? this.editableOrganisationType.logoMediaLibraryID : null,
              logoImageKey: this.editableOrganisationType.logoImageKey ? this.editableOrganisationType.logoImageKey : null,
              mapPinMediaLibraryID: this.editableOrganisationType.mapPinMediaLibraryID ? this.editableOrganisationType.mapPinMediaLibraryID : null,
              mapPinImageKey: this.editableOrganisationType.mapPinImageKey ? this.editableOrganisationType.mapPinImageKey : null,
              renderingPath: this.editableOrganisationType.renderingPath ? this.editableOrganisationType.renderingPath : null,
            }
          })
          .then(response => {
            console.log("PUT existing response", response)
            this.$store.commit("completeLoading")
            window.alert("Successfully Saved")
          })
          .catch(e => {
            console.error("Problem PUTing existing org type", e)
            this.$store.commit("completeLoading")
            window.alert("Couldn't save - Check your required fields")
          })
        })
      } else {
        console.log("method for new org type")
        this.$getCurrentUserJwToken().subscribe((jwt) => {

          axios({
            method: "POST",
            url: `${process.env.VUE_APP_ORGANISATIONAPI}/organisationtypes`,
            headers: { "Authorization": jwt },
            data: {
              name: this.editableOrganisationType.name ? this.editableOrganisationType.name : null,
              organisationTypeID: this.editableOrganisationType.organisationTypeID ? this.editableOrganisationType.organisationTypeID : null,
              shortName: this.editableOrganisationType.shortName ? this.editableOrganisationType.shortName : null,
              published: this.editableOrganisationType.published ? 1 : 0,
              informationPage: this.editableOrganisationType.informationPage ? this.editableOrganisationType.informationPage : null,
              informationPageButtonText: this.editableOrganisationType.informationPageButtonText ? this.editableOrganisationType.informationPageButtonText : null,
              textColour: this.editableOrganisationType.textColour ? this.editableOrganisationType.textColour : null,
              backgroundColour: this.editableOrganisationType.backgroundColour ? this.editableOrganisationType.backgroundColour : null,
              buttonIcon: this.editableOrganisationType.buttonIcon ? this.editableOrganisationType.buttonIcon : null,
              logoMediaLibraryID: this.editableOrganisationType.logoMediaLibraryID ? this.editableOrganisationType.logoMediaLibraryID : null,
              logoImageKey: this.editableOrganisationType.logoImageKey ? this.editableOrganisationType.logoImageKey : null,
              mapPinMediaLibraryID: this.editableOrganisationType.mapPinMediaLibraryID ? this.editableOrganisationType.mapPinMediaLibraryID : null,
              mapPinImageKey: this.editableOrganisationType.mapPinImageKey ? this.editableOrganisationType.mapPinImageKey : null,
              renderingPath: this.editableOrganisationType.renderingPath ? this.editableOrganisationType.renderingPath : null,
            }
          })
          .then(response => {
            //On Post need to route to edit for the new org type based on the response
            console.log("POST new response", response)
            this.$store.commit("completeLoading")
            //Grabs the new ID and changes heading / route
            this.$router.push({ path: `/organisationTypes/${response.data.body.organisationTypeID}` })
            this.heading = "Editing Organisation type"
            this.isExistingType = true
            this.isLoaded = true
            window.alert("Successfully Saved")
          })
          .catch(e => {
            console.error("Problem posting new org type", e)
            this.$store.commit("completeLoading")
            window.alert("Couldn't save - Check your required fields")
          })
        })
      }
    },
    updateLogo(image) {
      this.editableOrganisationType.logoMediaLibraryID = image.mediaLibraryID
      this.editableOrganisationType.logoImageKey = image.imageKey
    },
    updateMapPin(image) {
      this.editableOrganisationType.mapPinMediaLibraryID = image.mediaLibraryID
      this.editableOrganisationType.mapPinImageKey = image.imageKey
    }
  }
}
</script>

<style scoped lang='scss'>
.half {
    width: 50%;
  }

.section-divider {
  margin-bottom: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid #DFE3E9;
}


.player {
  &__checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__toggle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

    &__panel {
        border: 1px solid $lightgrey;
        background-color: $white;
        padding: 20px;
        margin-bottom: 20px;
        &-details {
            flex-direction: column;
            @media (min-width: 960px) {
                flex-direction: row;
            }
        }
        &-img {
            img {
                width: 100%;
            }
        }
        &-opta {
            &-cb {
                label {
                    margin-bottom: 0;
                }
            }
        }
        &--column {
          flex-direction: column;
          width: 100%;
        }
    }
}
.input {
    &__textfield {
        &--basic {
            margin-top: 0;
        }
    }
}

.note {
  padding: 10px;
  margin: 0 10px 30px;
  font-size: 13px;
  font-style: italic;
  background-color: #f4f6d2;
  border: 1px solid #d6d8b0;
  opacity: 0;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  transform: translateX(-30px);
}

.has-note:hover .note {
  opacity: 0.8;
  transform: translateX(0);
}


</style>
